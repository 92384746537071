import { App as AntdApp } from 'antd'
import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router'

import { App } from './App.jsx'
import Pages from './pages'

// TODO: remove if config in vite is enough
// import.meta.env.VITE_SENTRY_ENVIRONMENT !== 'local' &&
//   Sentry.init({
//     dsn: import.meta.env.VITE_SENTRY_DSN,
//     environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
//     integrations: [new Replay()],
//     tracesSampleRate: 0.2,
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0,
//     ignoreErrors: [
//       'ResizeObserver loop limit exceeded',
//       'ResizeObserver loop completed with undelivered notifications.'
//     ]
//   })

const router = createBrowserRouter([
  {
    path: '*',
    element: <Pages />
  }
])
const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <AntdApp>
    <App>
      <RouterProvider router={router} />
    </App>
  </AntdApp>
)
